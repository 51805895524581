/* SimpleMenu.module.css */

.zp-key-manager--simple-menu-button:focus {
  outline: 2px solid transparent;
  outline-offset: 2px;
}

.zp-key-manager--simple-menu-wrapper {
  position: relative;
}

.zp-key-manager--simple-menu-dialog {
  position: absolute;
  top: 0px;
  right: 0px;
  z-index: 50;
  border-radius: 0.25rem;
  background-color: rgb(255 255 255 / 1);
  box-shadow: 0 0 #0000, 0 0 #0000, 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
}

:is(.zp-key-manager--dark .zp-key-manager--simple-menu-dialog) {
  background-color: rgb(26 31 54 / 1);
}

.zp-key-manager--simple-menu-item-button {
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  white-space: nowrap;
  border-radius: 0.25rem;
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  text-align: right;
}

.zp-key-manager--simple-menu-item-button:hover {
  background-color: undefined;
}

.zp-key-manager--simple-menu-item-button:hover:hover {
  background-color: rgb(248 250 252 / 1);
}

:is(.zp-key-manager--dark .zp-key-manager--simple-menu-item-button):hover {
  color: undefined;
}

:is(.zp-key-manager--dark .zp-key-manager--simple-menu-item-button):hover:hover {
  color: rgb(255 255 255 / 1);
}

.zp-key-manager--dark .zp-key-manager--simple-menu-item-button:hover {
  background-color: #2a2f45;
}

.zp-key-manager--simple-menu-dropdown {
  padding: 0.25rem;
  color: rgb(39 39 42 / 1);
}

:is(.zp-key-manager--dark .zp-key-manager--simple-menu-dropdown) {
  color: rgb(228 228 231 / 1);
}

.zp-key-manager--simple-menu-item-icon {
  margin-right: 0.5rem;
  height: 1rem;
  width: auto;
}